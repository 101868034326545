import React from "react";

import Picture from "../../containers/Picture/Picture";

import "./Advantages.scss";
import "../../content.scss";

const Advantages = () => {
  return (
    <div className='container row'>
      <div className='content half advantages'>
        <div>
          <h2>Celovite in prilagojene rešitve</h2>
          <p>
            <strong>Na podlagi 25 let izkušenj v branži, vam lahko ponudimo odlično celovito rešitev za potrebe procesne tehnologije vašega podjetja.</strong>
          </p>
          <p>
            Skozi leta smo pridobili veliko kakovostnih partnerjev, s katerimi odlično sodelujemo in poiščemo najugodnejše rešitve za naše stranke. Pri nas je kvaliteta storitev in kakovost vgrajenih
            naprav na prvem mestu, prav zato sodelujemo le s prav posebnimi in izbranimi partnerji, ki slovijo po svoji kakovosti ali učinkovitosti.
          </p>
          <p>Če iščete nove rešitve za svoje poslovne procese na področju:</p>
          <ul className='row flex-wrap'>
            <li className='half'>sejanja</li>
            <li className='half'>sortiranja</li>
            <li className='half'>industrijskega pranja</li>
            <li className='half'>sušenja</li>
            <li className='half'>mešanja</li>
            <li className='half'>drobljenja</li>
            <li className='half'>mletja</li>
            <li className='half'>granuliranja</li>
            <li className='half'>peletiranja</li>
          </ul>
          <p>imamo garantirano najboljšo rešitev za vas.</p>
          <p>Za vas pripravimo:</p>
          <ul>
            <li>popolnoma prilagojene rešitve</li>
            <li>opravimo testiranja</li>
            <li>pomagamo, svetujemo in sodelujemo z različnimi dobavitelji pri izbiri najboljše opcije,</li>
            <li>pomagamo pri zagonu</li>
            <li>garantiramo servisne storitve in rezervne dele.</li>
          </ul>
        </div>
      </div>
      <div className='half'>
        <div className='aside'>
          <Picture file='allgaier-tumbler-sito' alt='Allgaier krožno oscilirajoče sito' />
          <Picture file='eirich-mesalec' alt='Eirich industrijski mešalec' />
          <Picture file='vortex-drobilci' alt='drobilci vortex' />
        </div>
      </div>
    </div>
  );
};
export default Advantages;
