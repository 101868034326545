import React from "react";

import "./Picture.scss";

const Picture = ({ file, className = null, alt }) => {
  const webp = require(`../../assets/images/${file}-s.webp`);
  const jpg = require(`../../assets/images/${file}-s.jpg`);
  return (
    <picture className={className}>
      <source srcSet={webp} type='image/webp' alt={alt} loading='lazy' />
      <source srcSet={jpg} type='image/jpeg' alt={alt} loading='lazy' />
      <img src={jpg} alt={alt} loading='lazy' />
    </picture>
  );
};
export default Picture;
