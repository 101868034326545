// import React, { Suspense, useCallback, useEffect } from "react";
import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Layout from "./hoc/Layout/Layout";
// import Loader from "./shared/Loader/Loader";
import * as actions from "./store/actions/";

// const components = {
//   home: React.lazy(() => {
//     return import("./pages/Home/Home");
//   }),
//   about: React.lazy(() => {
//     return import("./pages/About/About");
//   }),
//   brands: React.lazy(() => {
//     return import("./pages/Brands/Brands");
//   }),
//   advantages: React.lazy(() => {
//     return import("./pages/Advantages/Advantages");
//   }),
//   quote: React.lazy(() => {
//     return import("./pages/Quote/Quote");
//   }),
//   contact: React.lazy(() => {
//     return import("./pages/Contact/Contact");
//   }),
//   industries: React.lazy(() => {
//     return import("./pages/Industries/Industries");
//   }),
//   competencies: React.lazy(() => {
//     return import("./pages/Competencies/Competencies");
//   }),
//   privacy: React.lazy(() => {
//     return import("./pages/Privacy/Privacy");
//   }),
//   testing: React.lazy(() => {
//     return import("./pages/Testing/Testing");
//   }),
//   legal: React.lazy(() => {
//     return import("./pages/Legal/Legal");
//   })
// };

import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Brands from "./pages/Brands/Brands";
import Advantages from "./pages/Advantages/Advantages";
import Quote from "./pages/Quote/Quote";
import Contact from "./pages/Contact/Contact";
import Industries from "./pages/Industries/Industries";
import Competencies from "./pages/Competencies/Competencies";
import Privacy from "./pages/Privacy/Privacy";
import Testing from "./pages/Testing/Testing";
import Legal from "./pages/Legal/Legal";

const components = {
  home: Home,
  about: About,
  brands: Brands,
  advantages: Advantages,
  quote: Quote,
  contact: Contact,
  industries: Industries,
  competencies: Competencies,
  privacy: Privacy,
  testing: Testing,
  legal: Legal
};

const App = () => {
  const routesArray = useSelector(state => state.menus.routes);
  const menuItems = useSelector(state => state.menus.items);
  const activeMenu = useSelector(state => state.menus.active);
  const currentLocation = useLocation();

  const dispatch = useDispatch();
  const onSetActiveMenu = useCallback(menu => dispatch(actions.setActiveMenu(menu)), [dispatch]);

  useEffect(() => {
    const uri = currentLocation.pathname === "/" ? currentLocation.pathname : /([\w-]+)/.exec(currentLocation.pathname)[0];
    activeMenu !== uri && onSetActiveMenu(uri);
  }, [activeMenu, currentLocation.pathname, onSetActiveMenu]);

  const routes = routesArray.map(uri => <Route key={uri} path={`/${uri}/`} exact component={components[menuItems[uri].component]} />);

  return (
    <Layout>
      {/* <Suspense fallback={<Loader />}> */}
      <Switch>
        <Route path='/' exact component={components.home} />
        {routes}
      </Switch>
      {/* </Suspense> */}
    </Layout>
  );
};

export default App;
