import React from "react";

import "./Home.scss";
import "../../logos.scss";
import "../../content.scss";

import Picture from "../../containers/Picture/Picture";
import Form from "../../containers/Form/Form";

import { ReactComponent as AllgaierLogo } from "../../assets/logos/allgaier.svg";
import { ReactComponent as MozerLogo } from "../../assets/logos/mozer.svg";
import { ReactComponent as MogensenLogo } from "../../assets/logos/mogensen.svg";
import { ReactComponent as GosagLogo } from "../../assets/logos/gosag.svg";
import { ReactComponent as EirichLogo } from "../../assets/logos/eirich.svg";
import { ReactComponent as VortexLogo } from "../../assets/logos/vortex.svg";

const formElements = {
  name: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Vaše ime"
    },
    label: {
      show: false,
      name: "Ime in priimek"
    },
    validation: {
      required: true
    },
    valid: false,
    touched: false,
    value: ""
  },
  company: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Podjetje"
    },
    label: {
      show: false,
      name: "Podjetje"
    },
    validation: {
      required: true
    },
    valid: false,
    touched: false,
    value: ""
  },
  email: {
    elementType: "input",
    elementConfig: {
      type: "email",
      placeholder: "E-naslov"
    },
    label: {
      show: false,
      name: "E-naslov"
    },
    validation: {
      required: true
    },
    valid: false,
    touched: false,
    value: ""
  },
  phone: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Telefonska številka"
    },
    label: {
      show: false,
      name: "Telefon"
    },
    validation: {
      required: true
    },
    valid: false,
    touched: false,
    value: ""
  },
  message: {
    elementType: "textarea",
    elementConfig: {
      type: "text",
      placeholder: "Dodatne informacije"
    },
    label: {
      show: false,
      name: "informacije"
    },
    validation: {
      required: false
    },
    valid: false,
    touched: false,
    value: ""
  }
};

const Home = () => {
  return (
    <div className='container'>
      <div className='content home'>
        <div className='row flex-wrap'>
          <div className='half padding-text-left'>
            <h4>Naprave in celotni sistemi za procesno tehnologijo</h4>
            <p>Smo uradni zastopniki za vrhunske proizvajalce procesne tehnologije</p>
            <div className='home__logos'>
              <AllgaierLogo className='logos color allgaier' />
              <MozerLogo className='logos color mozer' />
              <MogensenLogo className='logos color mogensen' />
              <GosagLogo className='logos color gosag' />
              <EirichLogo className='logos color eirich' />
              <VortexLogo className='logos color vortex' />
            </div>
          </div>
          <div className='half'>
            <Picture file='allgaier-drum-drier' alt='bobnasti sušilec Allgaier' />
            <Picture file='eirich-mixer-home' alt='industrijski mešalec Eirich' />
          </div>
          <div className='third padding-text-left'>
            <h3>Ponujamo kvalitetne naprave in storitve za:</h3>
            <ul>
              <li>sejanje</li>
              <li>sortiranje</li>
              <li>sušenje</li>
              <li>hlajenje</li>
              <li>industrijsko pranje</li>
              <li>mešanje</li>
              <li>granuliranje</li>
              <li>peletiranje</li>
              <li>drobljenje</li>
              <li>mletje</li>
            </ul>
          </div>
          <div className='third'>
            <h3>Testirajte z vašim materialom</h3>
            <p>Testirajte vaš material na naših strojih in preverite koliko lahko izboljšate svoje proizvodne postopke. Nudimo storitve laboratorijskega testiranja in analizo proizvodnih procesov.</p>
            <p>
              <strong>Anonimnost testov je zagotovljena!</strong>
              <Picture file='eirich-mixer-testing' alt='testni center industrijski mešalec Eirich' />
            </p>
          </div>
          <div className='third padding-text-right'>
            <h3>Kontaktirajte nas</h3>
            <Form id='contact-form-contact' elements={formElements} submit='Pošlji' />
          </div>
          <div>
            <p>
              Naložbo izdelave spletnih strani <a href='/'>po-bitenc.com</a> sofinancirata Republika Slovenija in Evropska unija iz Evropskega sklada za regionalni razvoj. Sofinanciranje je bilo
              pridobljeno preko Vavčerja za digitalni marketing.
            </p>
          </div>
          <a href='https://www.eu-skladi.si/' rel='noopener' target='_blank' className='third'>
            <Picture file='eu-sklad-logo' alt='sklad za razvoj EU' />
          </a>
          <a href='https://podjetniskisklad.si/sl/' rel='noopener' target='_blank' className='third'>
            <Picture file='sl-podjetniski-sklad' alt='Slovenski podjetniški sklad' />
          </a>
          <a href='https://www.gov.si/drzavni-organi/ministrstva/ministrstvo-za-gospodarski-razvoj-in-tehnologijo/' rel='noopener' target='_blank' className='third'>
            <Picture file='rs-ministrstvo-razvoj' alt='Ministrstvo za gospodarki razvoj' />
          </a>
        </div>
      </div>
    </div>
  );
};
export default Home;
