import React from "react";

import "./Testing.scss";
import "../../content.scss";

import Picture from "../../containers/Picture/Picture";

const Testing = () => {
  return (
    <div className='container'>
      <div className='content testing'>
        <div className='row flex-wrap'>
          <div className='half padding-text-left'>
            <h2>Sodobno testno okolje za procesne tehnologije</h2>
            <p>
              Naši testni centri so opremljeni s vsemi najpomembnejšimi napravami iz našega prodajnega programa. Testni centri imajo dve pomembni vlogo in sicer izboljševanje tehnoloških rešitev ter
              inovacije tehnoloških procesov ter testiranje za pridobivanje natančnih podatkov o lastnostih strankinega materiala za boljše predvidevanje kapacitet ter kvalitete procesa.
            </p>
            <p>
              V testnem centru za vas opravimo vzorčno sejanje, sortiranje, sušenje, hlajenje, mešanje ali drobljenje z vašim materialom pod dejanskimi pogoji. Tako lahko zagotavljamo kapacitete in
              kvaliteto, vi pa lažje planirate vaše poslovanje ter zmanjšujete riziko.
            </p>
            <p>
              Nekateri procesi so izredno zapleteni vsak material ima določene posebnosti, ki pogosto povzročajo motnje v procesu, ki jih je nemogoče predvideti brez testiranja. Iz podatkov testiranja
              vam lahko lažje svetujemo katera naprava je za vas najprimernejša ter vam pripravimo prikrojeno rešitev in najboljšo ponudbo.
            </p>
            <p>Na podlagi opravljenih testov je mogoče načrtovati celoten sistem ter individualne nastavitve naprav, za čim večjo učinkovitost v produkcijskih pogojih.</p>
            <p>
              Vsak test je strogo zaupen, saj se zavedamo, da so informacije v poslovnem svetu izrednega pomena, še posebej pri inovacijah ter razvojnih projektih v katere je vloženih veliko sredstev.
            </p>
          </div>
          <div className='half'>
            <Picture file='testing-eirich-1' alt='testiranje, testni center, sita, mešalci, sortirniki, drobilci, sušilci' className='content-image' />
            <Picture file='testing-eirich-2' alt='testiranje, testni center, sita, mešalci, sortirniki, drobilci, sušilci' className='content-image' />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Testing;
