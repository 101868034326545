import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { ReactComponent as MenuIconSvg } from "../../assets/icons/menu.svg";

import * as actions from "../../store/actions/";

const MenuIcon = ({ color = "#222" }) => {
  const dispatch = useDispatch();
  const onMenuOpen = useCallback(() => dispatch(actions.setMenuOpen()), [dispatch]);

  return (
    <button id='menu--draw' className='icon' onClick={() => onMenuOpen()}>
      <MenuIconSvg fill={color} />
    </button>
  );
};
export default MenuIcon;
