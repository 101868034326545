import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import CookieConsent from "react-cookie-consent";

import "./Layout.scss";
import Seo from "../../containers/Seo/Seo";
import Backdrop from "./Backdrop/Backdrop";
import Header from "./Header/Header";
import Title from "./Title/Title";
import Footer from "./Footer/Footer";
import SiteMsg from "../../containers/SiteMsg/SiteMsg";

const Layout = props => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let desktop = width > 600;

  const expanded = useSelector(state => state.options.headerExpanded);

  return (
    <div id='theme' className={expanded ? "header-expanded" : null}>
      <Seo />
      <Backdrop />
      <Header desktop={desktop} />
      <Title />
      <div id='main'>{props.children}</div>
      <Footer />
      <SiteMsg />
      <CookieConsent acceptOnScroll={true} location='bottom' buttonText='Strinjam se' style={{ background: "#4e342e" }} buttonStyle={{ background: "#ffd54f", color: "#111", fontSize: "13px" }}>
        Ta stran uporablja piškotke, ki izboljšajo vašo uporabniško izkušnjo med brskanjem.
      </CookieConsent>
    </div>
  );
};

export default Layout;
