import React from "react";

import "./Industries.scss";
import "../../content.scss";

import Picture from "../../containers/Picture/Picture";

const Industries = () => {
  return (
    <div className='container'>
      <div className='content industries'>
        <h2>Procesna tehnologija - industrijske panoge</h2>
        <p>
          Nudimo kvalitetne in učinkovite rešitve na področju procesne tehnologije za različne industrijske panoge. V industrijskih panogah podpiramo procese sejanja, sortiranja, sušenja, hlajenja,
          pranja, mešanja, granuliranja, peletiranja, drobljenja in mletja.
        </p>
      </div>
      <div className='content industries'>
        <h2>Industrijske panoge</h2>
        <div className='row flex-wrap'>
          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-stone' alt='procesna oprema za kamnolome' />
              </div>
              <div className='card-content'>
                <h3>Kamnine in ruda</h3>
                <p>Sita, drobilci, sušilci in industrijsko pranje za:</p>
                <ul>
                  <li>Apnenec</li>
                  <li>Kalcijev karbonat</li>
                  <li>Dolomit</li>
                  <li>Diabaz</li>
                  <li>Granit</li>
                  <li>Kvarc ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-recycling' alt='procesna oprema za Odpad in recikliranje' />
              </div>
              <div className='card-content'>
                <h3>Odpad in recikliranje</h3>
                <p>Optične in druge vrste sortiranja za:</p>
                <ul>
                  <li>Steklo</li>
                  <li>Gumo</li>
                  <li>Aluminijaste odpadke</li>
                  <li>Odpadni les</li>
                  <li>Žlindro ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-chemical' alt='procesna oprema za kemično industrijo' />
              </div>
              <div className='card-content'>
                <h3>Kemična industrija</h3>
                <p>Sita, mešalci in sušilci za:</p>
                <ul>
                  <li>Karbonate</li>
                  <li>Kloride</li>
                  <li>Titanijev dioksid</li>
                  <li>Gumo</li>
                  <li>Pesticide ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-food' alt='procesna oprema za prehrambeno industrijo' />
              </div>
              <div className='card-content'>
                <h3>Prehrambena industrija</h3>
                <p>Presejalni stroji, mešanje, granuliranje, gnetenje, razprševanje, premazi...</p>
                <ul>
                  <li>Sladkor</li>
                  <li>Krma za živali</li>
                  <li>Otroška hrana</li>
                  <li>Čaji</li>
                  <li>Vitamini</li>
                  <li>Začimbe ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-mining' alt='procesna oprema za Rudarstvo (premog / ruda)' />
              </div>
              <div className='card-content'>
                <h3>Rudarstvo (premog / ruda)</h3>
                <p>Presejalni stroji, drobilci, sušilci, mešalci in pranje za:</p>
                <ul>
                  <li>Aktivno oglje</li>
                  <li>Antracit</li>
                  <li>Premog</li>
                  <li>Lignit</li>
                  <li>Grafit ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-metallurgy' alt='procesna oprema za metalurgijo' />
              </div>
              <div className='card-content'>
                <h3>Metalurgija</h3>
                <p>Presejalni stroji, sušilci, mešalci za:</p>
                <ul>
                  <li>Aluminij</li>
                  <li>Pepel</li>
                  <li>Baker</li>
                  <li>Železo</li>
                  <li>Magnetit v prahu</li>
                  <li>Niklov koncentrat ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-wood' alt='procesna oprema za Les in Iverne plošče' />
              </div>
              <div className='card-content'>
                <h3>Les / Iverne plošče</h3>
                <p>Presejalni in sušilni stroji za:</p>
                <ul>
                  <li>Celulozo</li>
                  <li>Pluto</li>
                  <li>Konopljo</li>
                  <li>Lesne sekance</li>
                  <li>Žagovino ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-plastic' alt='procesna oprema za proizvodnjo plastike' />
              </div>
              <div className='card-content'>
                <h3>Plastika</h3>
                <p>Presejalni stroji za:</p>
                <ul>
                  <li>EPS</li>
                  <li>Lateks</li>
                  <li>Najlon</li>
                  <li>PVC</li>
                  <li>Silikon ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-pharma' alt='procesna oprema za farmacijo' />
              </div>
              <div className='card-content'>
                <h3>Farmacija</h3>
                <p>Presejalni in sušilni stroji, mešalci, granulatorji za:</p>
                <ul>
                  <li>Antibiotiki</li>
                  <li>Kozmetika</li>
                  <li>Želatinaste kapsule</li>
                  <li>Farmacevtske tablete</li>
                  <li>Peleti iz železovega fosfata ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-cheramic' alt='procesna oprema za proizvodnjo keramike' />
              </div>
              <div className='card-content'>
                <h3>Keramika</h3>
                <p>Presejalni stroji, sušenje in mletje za:</p>
                <ul>
                  <li>Aluminijev oksid</li>
                  <li>Pigmenti (suspenzija)</li>
                  <li>Ognjevarni materiali</li>
                  <li>Silicijev karbid</li>
                  <li>Apatit</li>
                  <li>Keramične glazure ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-fodder' alt='procesna oprema za proizvodnjo krme za živino' />
              </div>
              <div className='card-content'>
                <h3>Živalska krma</h3>
                <p>Presejalni stroji za:</p>
                <ul>
                  <li>Krušni odpadki</li>
                  <li>Koruzna moka</li>
                  <li>Tropine</li>
                  <li>Krma iz oljne repice</li>
                  <li>Olupi sladkornega trsa ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-biofuel' alt='procesna oprema za proizvodnjo Bio goriva in Peletov' />
              </div>
              <div className='card-content'>
                <h3>Bio goriva / Peleti</h3>
                <p>Presejalni stroji za:</p>
                <ul>
                  <li>Lesni sekanci</li>
                  <li>Lesni ostružki</li>
                  <li>Žagovina</li>
                  <li>Oglje</li>
                  <li>Papirna žlindra ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-concrete' alt='procesna oprema za proizvodnjo betona in betonarne' />
              </div>
              <div className='card-content'>
                <h3>Beton</h3>
                <p>Mešalci za:</p>
                <ul>
                  <li>Beton za strešnike</li>
                  <li>Penjen beton</li>
                  <li>Žveplov beton</li>
                  <li>Ultra močan beton</li>
                  <li>Samozgoščevalni beton ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-mortar' alt='procesna oprema za proizvodnjo Suhe malte in ometa' />
              </div>
              <div className='card-content'>
                <h3>Suha malta in omet</h3>
                <p>Mešalci za:</p>
                <ul>
                  <li>Opečna malta</li>
                  <li>Notranji omet</li>
                  <li>Toplotno-izolacijski omet</li>
                  <li>Anhidritni estrih</li>
                  <li>Cementne mešanice ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-sand-lime' alt='procesna oprema za proizvodnjo Peščeno apnene opeke' />
              </div>
              <div className='card-content'>
                <h3>Peščeno apnena opeka</h3>
                <p>Mešalci in mešalni sistemi za:</p>
                <ul>
                  <li>Homogeno distribucijo vseh materialov</li>
                  <li>Vlaženje apna</li>
                  <li>Enotno strukturo opeke</li>
                  <li>Zanesljivo separacijo reaktivnih aglomeratov</li>
                  <li>Neprekinjen tok materiala ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-refactories' alt='procesna oprema za proizvodnjo ognjevarnih materialov' />
              </div>
              <div className='card-content'>
                <h3>Ognjevarni materiali</h3>
                <p>Mešalci in mešalni sistemi za proizvodnjo ognjevarnih materialov.</p>
                <ul>
                  <li>Zrnca za izostatično stisnjene izdelke</li>
                  <li>Toplotnoizolacijske spojine</li>
                  <li>Mešanice in zrnca za oksidne keramične in neoksidne keramične materiale</li>
                  <li>Malta in polnilni cement ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-glass' alt='procesna oprema za proizvodnjo stekla' />
              </div>
              <div className='card-content'>
                <h3>Steklo</h3>
                <p>
                  Mešalci za pripravo stekla. Steklene serije so sestavljene iz številnih posameznih komponent, ki se v nekaterih primerih precej razlikujejo. Naši mešalni stroji so namenjeni
                  ustvarjanju serije optimalne homogenosti in najnižje možne težnje k razmešanju.
                </p>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-lead-acid' alt='procesna oprema za proizvodnjo svinčene paste' />
              </div>
              <div className='card-content'>
                <h3>Svinčena pasta</h3>
                <p>
                  Edinstven postopek EVACTHERM<sup>®</sup>, ki ga je razvil EIRICH, po vsem svetu dokazuje svoje izjemno mesto pri okolju prijazni in varčni proizvodnji svinčevih spojin.
                </p>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-batteries' alt='procesna oprema za proizvodnjo sodobnih baterij' />
              </div>
              <div className='card-content'>
                <h3>Litij-ionske baterije</h3>
                <p>Sita in mešalci za potrebe proizvodnje sodobnih baterij.</p>
                <ul>
                  <li>Izjemna kvaliteta finega sejanja</li>
                  <li>En mešalec za celoten proces mešanja</li>
                  <li>
                    EVACMIX<sup>®</sup> vakuumska tehnologija za razmaščene prevlečne mešanice
                  </li>
                  <li>Kratek pripravljalni čas ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-friction' alt='procesna oprema za frikcijske obologe, zaviranje' />
              </div>
              <div className='card-content'>
                <h3>Frikcijske obloge</h3>
                <p>Eirich mešalci zagotavljajo očitne prednosti in prinašajo odlične rezultate za vse vrste oblog, ki se uporabljajo v proizvodnji:</p>
                <ul>
                  <li>Koles</li>
                  <li>Osebnih avtomobilov</li>
                  <li>Gospodarskih vozil</li>
                  <li>Železniških vozil ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-carbon-paste' alt='procesna oprema za proizvodnjo ogljikove paste' />
              </div>
              <div className='card-content'>
                <h3>Ogljikova pasta</h3>
                <p>Stroji in sistemi za predelavo ogljikove paste za proizvodnjo:</p>
                <ul>
                  <li>Anode in katode za elektrolizo primarnega aluminija</li>
                  <li>Soderberg pasta</li>
                  <li>Grafitne elektrode</li>
                  <li>Izdelki iz ogljikovih vlaken ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-foundry-sand' alt='procesna oprema za pripravo livarskega peska' />
              </div>
              <div className='card-content'>
                <h3>Livarski pesek</h3>
                <p>Prilagojeni sistemi za pripravo livarskega peska:</p>
                <ul>
                  <li>Priprava z zračnim hlajenjem</li>
                  <li>Priprava v vakuumu</li>
                  <li>Celoviti sistemi priprave livarskega peska ...</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='industries-fertilizer' alt='procesna oprema za proizvodnjo gnojil' />
              </div>
              <div className='card-content'>
                <h3>Gnojila</h3>
                <p>Inovativne rešitve za pripravo gnojil ne glede na zahteve:</p>
                <ul>
                  <li>Mešanje, granulacija in oblaganje v enem stroju</li>
                  <li>Optimalna porazdelitev elementov</li>
                  <li>Okolju prijazen postopek granulacije ...</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Industries;
