// AUTH
export const AUTH_SUCCESS = "AUTH_SUCCESS";

// OPTIONS
export const SET_MENU_OPEN = "SET_MENU_OPEN";
export const SET_MENU_CLOSE = "SET_MENU_CLOSE";
export const SET_BACKDROP_CLOSE = "SET_BACKDROP_CLOSE";
export const SET_HEADER_EXPANDED = "SET_HEADER_EXPANDED";
export const SET_MSG_START = "SET_MSG_START";
export const SET_MSG_END = "SET_MSG_END";

// MENUS
export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";

// FORMS
export const SEND_FORM_START = "SEND_FORM_START";
export const SEND_FORM_SUCCESS = "SEND_FORM_SUCCESS";
export const SEND_FORM_FAIL = "SEND_FORM_FAIL";
export const FORM_CLOSE_SNACK = "FORM_CLOSE_SNACK";
export const FORM_RESET = "FORM_RESET";
