import React from "react";

const Input = ({ elementType, elementConfig, label, id, value, changed, valid, shouldValidate }) => {
  let inputElement = null;

  switch (elementType) {
    case "textarea":
      inputElement = <textarea {...elementConfig} name={id} value={value} onChange={changed} className={!valid && shouldValidate ? "invalid" : ""} aria-label={label.name} />;
      break;
    case "checkbox":
      inputElement = <checkbox {...elementConfig} name={id} value={value} onChange={changed} className={!valid && shouldValidate ? "invalid" : ""} aria-label={label.name} />;
      break;
    default:
      inputElement = <input {...elementConfig} name={id} value={value} onChange={changed} className={!valid && shouldValidate ? "invalid" : ""} aria-label={label.name} />;
  }

  return (
    <label htmlFor={id}>
      {label.show && <span>{label.name}</span>}
      {inputElement}
    </label>
  );
};
export default Input;
