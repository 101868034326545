import React from "react";
import { Link } from "react-router-dom";

import "./Breadcrumbs.scss";

const Breadcrumbs = ({ uri, name }) => {
  return (
    <div id='breadcrumbs'>
      <ul>
        <li>
          <Link to='/'>PO Bitenc</Link>
        </li>
        <li>
          <Link to={uri}>{name}</Link>
        </li>
      </ul>
    </div>
  );
};
export default Breadcrumbs;
