import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../store/actions/";

import "./Form.scss";
import Input from "../Input/Input";

const ContactFormSmall = ({ id, elements, submit }) => {
  const uri = useSelector(state => state.menus.active);
  const dispatch = useDispatch();
  const onSetMsg = useCallback((msg, type) => dispatch(actions.setMsg(msg, type)), [dispatch]);
  const onSendForm = useCallback(formData => dispatch(actions.sendForm(formData)), [dispatch]);

  const [form, setForm] = useState(elements);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const elementsArray = Object.keys(form);

  const checkValidity = (value, rules) => {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    if (rules.minLength) {
      isValid = value.lenght >= rules.minLength && isValid;
    }
    if (rules.maxLength) {
      isValid = value.lenght >= rules.maxLength && isValid;
    }

    return isValid;
  };

  const inputChangedHandler = (event, element) => {
    setForm({
      ...form,
      [element]: {
        ...form[element],
        value: event.target.value,
        valid: checkValidity(event.target.value, form[element].validation),
        touched: true
      }
    });

    Object.values(form).find(e => e.valid === false) ? setIsFormValid(false) : setIsFormValid(true);
  };

  const formSubmitHandler = event => {
    event.preventDefault();
    setIsSending(true);
    setTimeout(function() {
      setIsSending(false);
    }, 4000);

    if (!isFormValid) return onSetMsg("Obrazec ni izpolnjen pravilno. Prosimo, izpolnite obrazec v celoti.", "error");

    let contents = "";
    for (let key in form) {
      contents += `${key}: ${form[key].value} \n`;
    }
    const formData = {
      to: "sandi@po-bitenc.si",
      replyTo: `${form.name.value} <${form.email.value}>`,
      message: {
        subject: "Kontaktni obrazec [PO BITENC]",
        text: `Pozdravljen Sandi

    Na vaših spletnih straneh je bil izpolnjen novi obrazec s sledečimi podatki:

    Datum: ${new Date().toLocaleDateString("sl-SI", { day: "numeric", month: "long", year: "numeric" })}
    Poslano iz strani: www.po-bitenc.si/${uri}
    Pošiljatelj: ${form.name.value}
    E-naslov pošiljatelja: ${form.email.value}
    Telefonska številka: ${form.phone.value}

    Vsebina obrazca:
${contents}

    Imej prečudovit dan...`
      }
    };
    onSendForm(formData);
  };

  return (
    <form id={id} onSubmit={formSubmitHandler}>
      {elementsArray.map(element => {
        return (
          <Input
            key={element}
            id={element}
            elementType={form[element].elementType}
            elementConfig={form[element].elementConfig}
            label={form[element].label}
            value={form[element].value}
            changed={event => inputChangedHandler(event, element)}
            valid={form[element].valid}
            shouldValidate={form[element].validation && form[element].touched}
          />
        );
      })}
      <input type='submit' value={submit} className={isFormValid && !isSending ? "" : "disabled"} />
    </form>
  );
};
export default ContactFormSmall;
