import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const Seo = () => {
  const menuItems = useSelector(state => state.menus.items);
  const activeItem = useSelector(state => state.menus.active);
  return (
    <Helmet>
      <html lang='sl' />
      <title>{menuItems[activeItem].seoTitle}</title>
      <meta name='description' content={menuItems[activeItem].seoDesc} />
    </Helmet>
  );
};
export default Seo;
