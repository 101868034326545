import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  menuOpen: false,
  backdrop: false,
  headerExpanded: false,
  msg: {
    content: null,
    type: null,
    open: false
  }
};

const setMenuOpen = (state, action) => {
  return updateObject(state, {
    menuOpen: true,
    backdrop: true
  });
};
const setMenuClose = (state, action) => {
  return updateObject(state, {
    menuOpen: false,
    backdrop: false
  });
};

const setBackdropClose = (state, action) => {
  return updateObject(state, {
    backdrop: false,
    menuOpen: false
  });
};

const setHeaderExpanded = (state, action) => {
  const expanded = state.headerExpanded !== action.expanded ? action.expanded : false;
  return updateObject(state, {
    headerExpanded: expanded
  });
};

const setMsgStart = (state, action) => {
  return updateObject(state, {
    msg: {
      content: action.msg,
      type: action.msgType,
      open: true
    }
  });
};

const setMsgEnd = (state, action) => {
  return updateObject(state, {
    msg: { ...state.msg, open: false }
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MENU_OPEN:
      return setMenuOpen(state, action);
    case actionTypes.SET_MENU_CLOSE:
      return setMenuClose(state, action);
    case actionTypes.SET_BACKDROP_CLOSE:
      return setBackdropClose(state, action);
    case actionTypes.SET_HEADER_EXPANDED:
      return setHeaderExpanded(state, action);
    case actionTypes.SET_MSG_START:
      return setMsgStart(state, action);
    case actionTypes.SET_MSG_END:
      return setMsgEnd(state, action);
    default:
      return state;
  }
};

export default reducer;
