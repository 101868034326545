import React from "react";
import { render } from "react-snapshot";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import menusReducer from "./store/reducers/menus";
import optionsReducer from "./store/reducers/options";

import "./index.scss";
import App from "./App";

import * as serviceWorker from "./serviceWorker";

const composeEnhancers = process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const rootReducer = combineReducers({
  menus: menusReducer,
  options: optionsReducer
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
const rootElement = document.getElementById("root");
render(app, rootElement);
// if (rootElement.hasChildNodes()) {
//   hydrate(app, rootElement);
// } else {
//   render(app, rootElement);
// }

serviceWorker.register();
