import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Header.scss";
import Logo from "../../../containers/Logo/Logo";
import MenuIcon from "../../../containers/Icons/MenuIcon";
// import SearchIcon from "../../../containers/Icons/SearchIcon";
import ContactIcon from "../../../containers/Icons/ContactIcon";
import Menu from "../../../components/Menu/Menu";
import TopBar from "../../../containers/TopBar/TopBar";

import * as actions from "../../../store/actions/";

const expandedContact = (
  <div id='expanded_contact'>
    <a className='dark' href='mailto:info@po-bitenc.si'>
      info@po-bitenc.si
    </a>
    <span className='phone withPhoneIcon'>+386 41 678 755</span>
  </div>
);

const Header = ({ desktop }) => {
  const expanded = useSelector(state => state.options.headerExpanded);
  const dispatch = useDispatch();
  const onHeaderExpanded = useCallback(expanded => dispatch(actions.setHeaderExpanded(expanded)), [dispatch]);

  return desktop ? (
    <div id='header'>
      <TopBar />
      <div id='header__logo_row'>
        <div className='container'>
          <Logo />
        </div>
      </div>
      <div id='menu'>
        <Menu desktop={desktop} />
      </div>
    </div>
  ) : (
    <div id='header'>
      <Menu />
      <div id='menu--icon'>
        <MenuIcon />
      </div>
      <div id='logo'>
        <Logo />
      </div>
      <div id='header__icons'>
        <ContactIcon click={onHeaderExpanded} />
        {/* <SearchIcon click={onHeaderExpanded} /> */}
      </div>
      <div className={`header__expanded ${expanded ? " show" : null}`}>
        <div className={`header__expanded-content ${expanded ? " show" : null}`}>{expanded === "contact" && expandedContact}</div>
      </div>
    </div>
  );
};
export default Header;
