import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  active: "/",
  items: {
    "/": {
      name: "Procesna oprema Bitenc",
      title: "Procesna oprema Bitenc",
      seoTitle: "Vrhunska procesna oprema in tehnologija | PO Bitenc",
      seoDesc: "Uradni zastopniki proizvajalcev vrhunske procesne tehnologije Allgaier, Mogensen, Mozer, Eirich, Eirich Impianti in Vortex.",
      component: "home"
    },
    "o-nas-procesna-oprema": {
      name: "O nas",
      title: "O nas - Procesna oprema Bitenc",
      seoTitle: "Procesna Oprema Bitenc | PO Bitenc",
      seoDesc: "Že več kot 25 let nudimo rešitve za procesno tehniko na področju Slovenije, Hrvaške, Srbije, Bosne in Hercegovine, Črne gore in Makedonije.",
      component: "about"
    },
    "nase-prednosti-mesalec-sito": {
      name: "Naše prednosti",
      title: "Naše prednosti na področju procesne tehnologije",
      seoTitle: "Učinkovita procesna oprema | PO Bitenc",
      seoDesc: "Naši stroji sodijo med najkvalitetnejše in najučinkovitejše. Oglejte si naša sita, mešalce, drobilce, mline, sušilnike, naprave za industrijsko pranje.",
      component: "advantages"
    },
    "industrija-procesna-tehnologija": {
      name: "Panoge",
      title: "Katere industrijske panoge pokrivamo?",
      seoTitle: "Procesna tehnologija za vse panoge | PO Bitenc",
      seoDesc: "Z našimi rešitvami pokrivamo vse industrijske panoge, od rudarstva, gradbeništva, steklarstva pa do prehrambene industrije in farmacije.",
      component: "industries"
    },
    "procesi-mesanje-sejanje-drobljenje": {
      name: "Procesi",
      title: "Tehnološki procesi",
      seoTitle: "Oprema za vse tehnološke procese | PO Bitenc",
      seoDesc: "Nudimo opremo za tehnološke procese sejanja, soritiranja, mešanja, granuliranja, peletiranja, drobljenja, mletja, sušenja, pranja in hlajenja.",
      component: "competencies"
    },
    "testiranja-procesna-oprema": {
      name: "Testiranja",
      title: "Testiranje opreme z vašim materialom",
      seoTitle: "Testiranje procesne opreme | PO Bitenc",
      seoDesc: "Nudimo vam možnost testiranja naših naprav z vašim materialom. Prepričajte se o učinkovitosti naših sistemov in izboljšajte svojo produktivnost.",
      component: "testing"
    },
    "pridobite-ponudbo": {
      name: "Pridobite ponudbo",
      title: "Pridobite ponudbo",
      seoTitle: "Ponudba za procesno tehniko | PO Bitenc",
      seoDesc: "Pridobite ponudbo za sito, drobilec, mešalec, mlin... Nudimo posamezne stroje in celotne rešitve za vaš tehnološki proizvodni proces.",
      component: "quote",
      desktop: false
    },
    "kontakt-procesna-tehnologija": {
      name: "Kontakt",
      title: "Kontakt - Procesna oprema Bitenc",
      seoTitle: "Kontaktirajte nas | PO Bitenc",
      seoDesc: "Naši kontaktni podatki: Procesna oprema Bitenc d.o.o., Sp. Voličina 26, 2232 Voličina, info@po-bitenc.si, +386 41 678 755",
      component: "contact"
    },
    proizvajalci: {
      name: "Proizvajalci",
      title: "Proizvajalci procesne opreme",
      seoTitle: "Allgaier, Eirich, Vortex | PO Bitenc",
      seoDesc: "Nudimo procesno opremo top kvalitete proizvajalcev Allgaier, Mogensen, Mozer, Eirich, Eirich Impianti in Vortex",
      component: "brands"
    },
    "pravne-informacije": {
      name: "Pravne informacije",
      title: "Pravne informacije",
      seoTitle: "Pravne informacije | PO Bitenc",
      seoDesc: "PO Bitenc, pravne informacije o uporabi spletnih strani.",
      component: "legal"
    },
    "obvestilo-o-zasebnosti": {
      name: "Obvestilo o zasebnosti",
      title: "Obvestilo o zasebnosti",
      seoTitle: "Obvestilo o zasebnosti | PO Bitenc",
      seoDesc: "Procesna oprema Bitenc. Skrbimo, da so vaši podatki pri nas varni in cenomo vašo zasebnost.",
      component: "privacy"
    }
  },
  routes: [
    "o-nas-procesna-oprema",
    "nase-prednosti-mesalec-sito",
    "industrija-procesna-tehnologija",
    "procesi-mesanje-sejanje-drobljenje",
    "testiranja-procesna-oprema",
    "pridobite-ponudbo",
    "kontakt-procesna-tehnologija",
    "proizvajalci",
    "pravne-informacije",
    "obvestilo-o-zasebnosti"
  ],
  main: ["o-nas-procesna-oprema", "nase-prednosti-mesalec-sito", "industrija-procesna-tehnologija", "procesi-mesanje-sejanje-drobljenje", "testiranja-procesna-oprema", "proizvajalci"],
  secondary: ["pridobite-ponudbo", "kontakt-procesna-tehnologija"],
  services: ["proizvajalci", "testiranja-procesna-oprema", "pridobite-ponudbo", "kontakt-procesna-tehnologija"],
  legal: ["pravne-informacije", "obvestilo-o-zasebnosti"]
};

const setActiveMenu = (state, action) => {
  return updateObject(state, {
    active: action.menu
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_MENU:
      return setActiveMenu(state, action);
    default:
      return state;
  }
};

export default reducer;
