import React from "react";

import "./Quote.scss";
import "../../content.scss";
import Form from "../../containers/Form/Form";

const formElements = {
  name: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Vaše ime"
    },
    label: {
      show: false,
      name: "Ime in priimek"
    },
    validation: {
      required: true
    },
    valid: false,
    touched: false,
    value: ""
  },
  company: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Podjetje"
    },
    label: {
      show: false,
      name: "Podjetje"
    },
    validation: {
      required: true
    },
    valid: false,
    touched: false,
    value: ""
  },
  email: {
    elementType: "input",
    elementConfig: {
      type: "email",
      placeholder: "E-naslov"
    },
    label: {
      show: false,
      name: "E-naslov"
    },
    validation: {
      required: true
    },
    valid: false,
    touched: false,
    value: ""
  },
  phone: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Telefonska številka"
    },
    label: {
      show: false,
      name: "Telefon"
    },
    validation: {
      required: true
    },
    valid: false,
    touched: false,
    value: ""
  },
  message: {
    elementType: "textarea",
    elementConfig: {
      type: "text",
      placeholder: "Dodatne informacije"
    },
    label: {
      show: false,
      name: "informacije"
    },
    validation: {
      required: false
    },
    valid: false,
    touched: false,
    value: ""
  }
};

const Quote = () => {
  return (
    <div className='container row'>
      <div className='content half quote'>
        <div>
          <h2>Pridobite ponudbo</h2>
          <p>Želite uradno ponudbo proizvajalcev procesne opreme Allgaier, Mogensen, Mozer, Gosag, Eirich, Eirich Impianti ali Vortex?</p>
          <p>Proces izdelave ponudbe ni enostaven zato vas prosimo, da nam pustite nekaj vaših podatkov in kontaktirali vas bomo z več informacijami.</p>
          <p>Pomagali vam bomo pripraviti vse potrebne informacije za pridobitev zavezujoče ponudbe ter sodelovali z vašo tehnološko ekipo, za čim boljšo optimizacijo vašega poslovanja.</p>
          <p>
            V kolikor bi bilo za vas smiselno opraviti testiranja z vašim materialom, bomo tudi to organizirali skupaj, tako da se boste vi lahko nemoteno posvetili svojim poslovnim procesom, ostalo
            pa prepustili nam.
          </p>
          <p>Vodili vas bomo skozi ves postopek od začetka pa do zagona novega stroja in vam nato nudili podporo še naprej, v kolikor jo boste potrebovali.</p>
          <p>Izpolnite kontaktni obrazec in kmalu se vam oglasimo.</p>
        </div>
      </div>
      <div className='base-bg half padding-container'>
        <div className='dark'>
          <h2 className='text-center'>Kontakt za ponudbo</h2>
          <Form id='contact-form-quote' elements={formElements} submit='Pošlji' />
        </div>
      </div>
    </div>
  );
};
export default Quote;
