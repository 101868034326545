import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as LogoSvg } from "../../assets/images/procesna-oprema-logo.svg";

const Logo = ({ color = "#222", width }) => {
  return (
    <Link id='logo--link' to='/'>
      <LogoSvg fill={color} style={{ maxWidth: width }} />
    </Link>
  );
};
export default Logo;
