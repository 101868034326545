import React from "react";

import { ReactComponent as ContactIconSvg } from "../../assets/icons/contact.svg";

const ContactIcon = ({ color = "#222", click }) => {
  return (
    <button className='icon contact' onClick={() => click("contact")}>
      <ContactIconSvg fill={color} />
    </button>
  );
};
export default ContactIcon;
