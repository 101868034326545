import { formsRef } from "../../firebase";
import * as actions from "../../store/actions/";

export const sendForm = formData => {
  return dispatch => {
    formsRef
      .add(formData)
      .then(() => {
        dispatch(actions.setMsg("Vaš obrazec je bil uspešno poslan. Hvala!", "ok"));
      })
      .catch(error => {
        let err = "Nekaj je šlo narobe. Prosimo, poskusite ponovno.";
        if (error.response && error.response.data.message) {
          err = error.response.data.message;
        } else {
          err = "Napaka: " + error.message;
        }
        dispatch(actions.setMsg(err, "error"));
      });
  };
};
