import React from "react";

import "./About.scss";
import "../../content.scss";

import Picture from "../../containers/Picture/Picture";

const About = () => {
  return (
    <div className='container'>
      <div className='content about'>
        <div className='row flex-wrap'>
          <div className='half padding-text-left'>
            <h4>PO Bitenc d.o.o. zagotavlja kakovostne rešitve iz področja procesne tehnologije</h4>
            <p>podjetjem na področju</p>
            <ul>
              <li>Slovenije,</li>
              <li>Hrvaške,</li>
              <li>Srbije,</li>
              <li>Bosne,</li>
              <li>Črne Gore in</li>
              <li>Makedonije.</li>
            </ul>
            <h4 className='padding-top'>Nudimo stroje in celovite rešitve proizvajalcev Allgaier, Mogensen, Mozer, Gosag, Eirich in Vortex za potrebe industrijskega:</h4>
            <ul>
              <li>sejanja,</li>
              <li>sortiranja,</li>
              <li>sušenja,</li>
              <li>hlajenja,</li>
              <li>pranja</li>
              <li>mešanja</li>
              <li>granuliranja</li>
              <li>peletiranja</li>
              <li>drobljenja in</li>
              <li>mletja.</li>
            </ul>
            <h4 className='padding-top'>Pokrivamo potrebe opreme industrijskih področij kot so:</h4>
            <ul>
              <li>kamnolomi</li>
              <li>odpadki / recikliranje</li>
              <li>kemična industrija</li>
              <li>živilska industrija</li>
              <li>rudarstvo (premog / ruda)</li>
              <li>metalurgija</li>
              <li>les / iverne plošče</li>
              <li>plastika</li>
              <li>farmacevtski izdelki</li>
              <li>keramika</li>
              <li>krma</li>
              <li>bio gorivo / peleti</li>
            </ul>
            <h4 className='padding-top'>Med naše zadovoljne stranke sodijo tudi:</h4>
            <p>
              Cinkarna Celje, Calcit , Termit Moravče, TKI Hrastnik, Talum, Silkem, Kema Puconci, Steklarna Rogaška Slatina, Steklarna Hrastnik, TAB Mežica, Swatycomet , Kovis, Samoborka, Holcim,
              Kaming Ljubeščica, Šečerana Osijek, Beton Lučko, Roefix, Knauf, Afarak, MAT Produkt, Barit Kreševo, Baumit, Calucem, ITV Raša, Carmeuse, Sisecam, Henkel, A&N Mikrogranulat.
            </p>
          </div>
          <div className='half'>
            <Picture file='zvonko-bitenc' alt='Zvonko Bitenc, direktor procesne opreme Bitenc' />
            <h4>PO Bitenc – 25 let tradicije</h4>
            <p>
              Začetki podjetja segajo v leto 1995, ko je Zvonko Bitenc vzpostavil prve kontakte s podjetjem Allgaier na sejmu POWTECH v Nurnbergu. Še isto leto je izvedel številna izobraževanja in
              pričel z aktivnim delom.
            </p>
            <p>Leta 1996 je prevzel samo stojno zastopanje skupine Allgaier za področje Slovenije, Hrvaške, Srbije, Bosne, Črne Gore in Makedonije, ki je do tedaj potekalo iz Dunaja.</p>
            <p>
              Prvi cilji so bili kupcem predstaviti prodajni program in novosti skupine Allgaier z vključenimi podjetji Allgaier, Mogensen in Mozer. Vrsto let smo bili prisotni tudi na Zagrebškem
              velesejmu.
            </p>
            <p>
              <strong>
                Naš cilj najti najugodnejšo in najkvalitetnejšo rešitev za naše stranke. Reševanje njihovih proizvodnih problemov in optimizacija njihovih procesov sta glavni vodili našega poslovanja
                od začetka naše poslovne poti.
              </strong>
            </p>
            <p>Leta 2002 smo v ponudbo vključili še podjetji Eirich in Vortex, ki sta avtoriteti na področju mešanja, granuliranja in peletiranja ter drobljenja.</p>
            <p>
              Z Eirich Impianti smo leta 2017 dodali še kvalitetne mline, kar pomeni, da lahko sedaj ponudimo celostne rešitve iz področja procesne opreme vrhunskih proizvajalcev. Naš poudarek je na
              kvaliteti.
            </p>
            <p>
              Skozi leta smo za naše stranke opravili že preko 500 testiranj, s katerimi smo pridobili točne podatke o kvaliteti in kapaciteti posameznih strojev na materialu, ki ga je dostavila
              stranka. Tako kupec natančno ve, kaj lahko pričakuje od kupljene naprave in to upošteva v svojih kalkulacijah, s tem pa znižuje riziko in izboljšuje planiranje ter optimizacijo
              poslovanja.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
