import React from "react";

import "../../content.scss";

const Legal = () => {
  return (
    <div className='container'>
      <div className='content legal'>
        <h2>
          Splošni pogoji uporabe spletne aplikacije <a href='/'>www.po-bitenc.si</a>
        </h2>
        <p>
          Dobrodošli v spletni aplikaciji po-bitenc.si. S spletno aplikacijo upravlja podjetje Procesna oprema Bitenc d.o.o., ponudnik storitev e-poslovanja (v nadaljevanju »ponudnik«). Za vse
          uporabnike veljajo v nadaljevanju navedeni splošni pogoji, ki jih je potrebno upoštevati. Splošni pogoji so sestavljeni v skladu z veljavno zakonodajo v Sloveniji. V primeru, da se z
          navedenimi splošnimi pogoji ne strinjate, vas prosimo, da spletno stran zapustite. Pridržujemo si pravico do spremembe splošnih pogojev na spletnih straneh po-bitenc.si kadarkoli in brez
          predhodnega obvestila. Vse spremembe splošnih pogojev so za uporabnike zavezujoče. Uporabnik je vsak, ki uporablja aplikacijo: po-bitenc.si. Podatki o ponudniku:
        </p>
        <ul>
          <li>Procesna oprema Bitenc d.o.o.</li>
          <li>Sp. Voličina 26, 2232 Voličina</li>
          <li>Davčna številka: SI28591755</li>
          <li>Matična številka: 1552732000</li>
          <li>IBAN SI56 0312 1100 0741 766 (SKB d.d.)</li>
          <li>Kontaktni telefon: 041 678 755</li>
        </ul>
        <h3>Garancija</h3>
        <p>
          Ponudnik garantira dostopnost portala 24 ur na dan 365 dni v letu. Nedostopnost portala zaradi rednih vzdrževalnih del ali napak ne bo obsegala več kot 10% obratovalnega časa. Ponudnik ne
          garantira za nedostopnost portala zaradi ne posodobljene programske opreme ali drugih težav na uporabnikovi strani.
        </p>
        <h3>Omejitev odgovornosti</h3>
        <p>
          Podjetje Procesna oprema Bitenc d.o.o. se po svojih najboljših močeh trudi zagotoviti ažurnost, točnost in popolnost podatkov, objavljenih na tej spletni strani. Prav tako si podjetje
          pridržuje pravico do spreminjanja vsebine spletne strani brez predhodnega obvestila. Čeprav se podjetje trudi zagotoviti natančne fotografije, je potrebno vse fotografije jemati kot
          simbolične. Fotografije ne zagotavljajo nobenih lastnosti ali drugih garancij kvalitete vsebin, na katere se navezujejo.
        </p>
        <h3>Informiranje o storitvah</h3>
        <p>
          Podjetje Procesna oprema Bitenc d.o.o. se po najboljših močeh trudi zagotoviti kvalitetne in strokovne informacije o storitvah v ponudbi. Kupec se je dolžan sam informirati o učinkih
          ponujenih storitev in se v primeru dvoma posvetovati z usposobljeno osebo. Procesna oprema Bitenc d.o.o. ne prevzema nobene odgovornosti za uporabo ali apliciranje vsebin. Procesna oprema
          Bitenc d.o.o. prav tako ne jamči za verodostojnost in pravilnost podatkov zapisanih na spletnih straneh po-bitenc.si. Obiskovalec se je dolžan sam dodatno informirati o vsebinah.
        </p>
        <h3>Postopek registracije uporabnikov</h3>
        <p>
          Strani spletnega aplikacije po-bitenc.si so na voljo tako registriranim uporabnikom kot tudi anonimnim obiskovalcem spletnega portala. Vsak uporabnik pri brezplačni registraciji prejme svoje
          geslo. Vsak uporabnik zagotavlja, da bo s svojim uporabniškim imenom in geslom razpolagal izključno sam oz. njegov pooblaščenec.
        </p>
        <h3>Avtorska zaščita</h3>
        <p>
          Vsebine na spletni strani po-bitenc.si so last podjetja Procesna oprema Bitenc d.o.o.. Vsakršno kopiranje, uporaba, objava, distribucija spletnih vsebin oz. posameznih delov le-teh, je brez
          pisnega dovoljenja podjetja Procesna oprema Bitenc d.o.o., prepovedano.
        </p>
        <h3>Pritožbe in spori</h3>
        <p>
          Za morebitne pritožbe je kupcu na voljo elektronski naslov info@po-bitenc.si. Ponudnik se po vseh močeh trudi izpolnjevati svojo dolžnost vzpostaviti učinkovit sistem obravnavanja pritožb.
          Ponudnik si po svojih najboljših močeh prizadeva, da se morebitni spori rešijo sporazumno. V nasprotnem primeru bo reševanje sporov reševalo pristojno sodišče v Mariboru.
        </p>
        <h3>Pomoč in dodatna vprašanja</h3>
        <p>V primeru kakršnihkoli dodatnih informacij in vprašanj, ki se navezujejo na našo ponudbo ali uporabo spletne aplikacije, nam pišite na info@po-bitenc.si.</p>
        <h3>Varovanje osebnih podatkov</h3>
        <p>Sestavni del teh Splošnih pogojev poslovanje je tudi izjava o Varovanju osebnih podatkov.</p>
        <p>
          <i>Datum izdaje splošnih pogojev: 10. februar 2020.</i>
        </p>
      </div>
    </div>
  );
};
export default Legal;
