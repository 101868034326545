import React from "react";

import "./Contact.scss";
import "../../content.scss";
import Form from "../../containers/Form/Form";
import PhoneIcon2 from "../../containers/Icons/PhoneIcon2";
import MailIcon from "../../containers/Icons/MailIcon";
import { ReactComponent as Drzave } from "../../assets/images/drzave.svg";

const formElements = {
  name: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Vaše ime"
    },
    label: {
      show: false,
      name: "Ime in priimek"
    },
    validation: {
      required: true
    },
    valid: false,
    touched: false,
    value: ""
  },
  company: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Podjetje"
    },
    label: {
      show: false,
      name: "Podjetje"
    },
    validation: {
      required: true
    },
    valid: false,
    touched: false,
    value: ""
  },
  email: {
    elementType: "input",
    elementConfig: {
      type: "email",
      placeholder: "E-naslov"
    },
    label: {
      show: false,
      name: "E-naslov"
    },
    validation: {
      required: true
    },
    valid: false,
    touched: false,
    value: ""
  },
  phone: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Telefonska številka"
    },
    label: {
      show: false,
      name: "Telefon"
    },
    validation: {
      required: true
    },
    valid: false,
    touched: false,
    value: ""
  },
  message: {
    elementType: "textarea",
    elementConfig: {
      type: "text",
      placeholder: "Dodatne informacije"
    },
    label: {
      show: false,
      name: "informacije"
    },
    validation: {
      required: false
    },
    valid: false,
    touched: false,
    value: ""
  }
};

const Contact = () => {
  return (
    <div className='container row flex-wrap contact'>
      <div className='content half'>
        <div>
          <h2>Procesna oprema Bitenc</h2>
          <p>
            <strong>Vas zanima kvalitetna oprema za vaše proizvodne procese?</strong>
          </p>
          <p>Ponujamo stroje in celostne rešitve na področju procesne tehnologije:</p>
          <ul className='row flex-wrap'>
            <li className='half'>sejanje</li>
            <li className='half'>sortiranje</li>
            <li className='half'>industrijsko pranje</li>
            <li className='half'>sušenje</li>
            <li className='half'>mešanje</li>
            <li className='half'>drobljenje</li>
            <li className='half'>mletje</li>
            <li className='half'>granuliranje</li>
            <li className='half'>peletiranje</li>
          </ul>
          <p>Za vas organiziramo testiranja z vašim materialom ter servisne in inženirske storitve.</p>
          <p>
            Naša področja poslovanja: <strong>Slovenija, Hrvaška, Srbija, Bosna, Črna Gora in Makedonija.</strong>
          </p>
        </div>
      </div>
      <div className='half padding-container'>
        <div>
          <h2 className='text-center'>Hiter kontakt</h2>
          <Form id='contact-form-contact' elements={formElements} submit='Pošlji' />
        </div>
      </div>
      <div className='third padding-container'>
        <h3>Podjetje</h3>
        <address>
          <p>
            <strong>Procesna oprema Bitenc d.o.o.</strong>
          </p>
          <p>Sp. Voličina 26 2232 Voličina</p>
          <p>ID za DDV: SI28591755</p>
        </address>
      </div>
      <div className='third padding-container'>
        <h3>Zvonko Bitenc</h3>
        <p>
          <MailIcon />
          <a href='mailto:info@po-bitenc.si'>info@po-bitenc.si</a>
        </p>
        <p>
          <PhoneIcon2 />
          +386 41 678 755
        </p>
      </div>
      <div className='third padding-container'>
        <h3>Sandi Bitenc</h3>
        <p>
          <MailIcon />
          <a href='mailto:sandi@po-bitenc.si'>sandi@po-bitenc.si</a>
        </p>
        <p>
          <PhoneIcon2 />
          +386 40 246 245
        </p>
      </div>
      <div className='full padding-bottom drzave'>
        <Drzave alt='zastopanje procesna oprema države' />
      </div>
    </div>
  );
};
export default Contact;
