import React from "react";

import "./Competencies.scss";
import "../../content.scss";

import Picture from "../../containers/Picture/Picture";

const Competencies = () => {
  return (
    <div className='container'>
      <div className='content competencies'>
        <h2>Procesna tehnologija - tehnološki procesi</h2>
        <div className='row flex-wrap'>
          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='comp-screening' alt='sita, presejalne naprave' />
              </div>
              <div className='card-content'>
                <h3>Sejanje</h3>
                <p>
                  <strong>Sejanje do ultra finega prahu.</strong>
                </p>
                <p>
                  Allgaier je vodilni dobavitelj sit in presejalnih naprav v Evropi. Možne so standardne in individualne prilagoditve sejanja tudi za visoko zahtevne branže kot sta farmacija in
                  prehrambena industrija.
                </p>
                <p>Krožne, oscilirajoče presejalne naprave nudijo izjemno natančne odsevke, medtem ko viracijska sita nudijo visoko učinkovitost in prepustnost.</p>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='comp-sorting' alt='optično, denzimetrično sortiranje' />
              </div>
              <div className='card-content'>
                <h3>Sortiranje</h3>
                <p>
                  <strong>Sortiranje surovin na podlagi barv, svetlosti, velikosti in gostote.</strong>
                </p>
                <p>
                  Sodobne naprave za optično sortiranje delujejo preko specialnih kamer in pnevmatičnega sistema. Hitro in učinkovito ločijo različne oblike, velikosti in odtenke vhodnega materiala.
                </p>
                <p>Še posebej so primerne za sortiranje in reciklažo odpadnih materialov. Naprave je možno nadgraditi s senzorji za detekcijo raznih snovi, kot so železo in drugi minerali.</p>
                <p>Ločevalne mize sortirajo material glede na razliko v teži in gostoti. Primerne so za industrijo lesa, kemikalij, stekla in drugih materialov.</p>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='comp-drying' alt='sušenje procesna oprema, sušilci' />
              </div>
              <div className='card-content'>
                <h3>Sušenje</h3>
                <p>
                  <strong>Pravi tehnološki proces sušenja za pravi material.</strong>
                </p>
                <p>Allgaier ponuja različne sisteme sušenja: vrteče bobnaste sušilnike, sušenje s fluidiziranim slojem ter diskovne sušilce.</p>
                <p>Uporabljajo se v pripravi gradbenih materialov, rudarstvu, plastični in keramični industriji, proizvodnji krme, pigmentov, bio-goriv in pesticidov.</p>
                <p>Ponujamo celotne sisteme, ki ob sušenju vključujejo tudi hlajenje, kalciniranje, granuliranje in sejanje.</p>
                <p>Kontaktni CD sušilci so inovacija na tržišču in nudijo večji prenos toplote kot enako veliki kontaktni sušilci, to pa pomeni prihranek pri energiji.</p>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='comp-cooling' alt='procesna oprema za hlajenje' />
              </div>
              <div className='card-content'>
                <h3>Hlajenje</h3>
                <p>
                  <strong>Nežno in zanesljivo hlajenje s prihranki energije.</strong>
                </p>
                <p>
                  V veliko primerih je potrebno posušen ali vroč material ohladiti pred vstopom v naslednji proizvodni proces. Za takšne primere ponujamo bobne za hlajenje in hlajenje na principu
                  fluidiziranega sloja. Možne so tudi kombinacije naprav za sušenje in hlajenje.
                </p>
                <p>Naprave so izdelane z mislijo na zniževanje stroškov energije in varovanje okolja, hkrati pa mora biti hlajenje prijazno do materiala in zanesljivo.</p>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='comp-washing' alt='procesna oprema za industrijsko pranje' />
              </div>
              <div className='card-content'>
                <h3>Industrijsko pranje</h3>
                <p>
                  <strong>Zanesljivo, natančno in učinkovito pranje.</strong>
                </p>
                <p>
                  Pranje je pomembno za čiščenje mineralov in peska, odstranjevanje tujkov, upravljanje z žlindro in onesnaženo zemljo, proizvodnjo visoko kakovostnega peska kot tudi pravilno
                  razporeditev velikosti delcev v materialu.
                </p>
                <p>Posebne metode povečane frikcije med delci zagotavljajo, da ob proizvodnji visoko kakovostnega peska ne izgubljate finega materiala.</p>
                <p>Ker je sistem modularen, ga lahko prilagodimo z različnimi črpalkami, motorji, rezervoarji in hidrocikloni.</p>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='comp-mixing' alt='procesna oprema za mešanje' />
              </div>
              <div className='card-content'>
                <h3>Mešanje</h3>
                <p>
                  <strong>Mešalci za preko 300 industrijskih branž.</strong>
                </p>
                <p>Več kot 100 let razvoja pomeni tehnološko dovršene in najbolj zaželene industrijske mešalce proizvajalca Eirich.</p>
                <p>Na voljo so majhni, laboratorijski mešalci od 1 do 10 litrov in intenzivni mešalci z ločenim mešanjem in transportom za neprekinjen proces.</p>
                <p>Naši mešalci so vsestranski, hitri in učinkoviti in jih je možno nadgrajevati brez omejitev.</p>
                <p>Na voljo so z različnimi higienskimi standardi za potrebe prehrambene in farmacevtske industrije.</p>
                <p>Možni so tudi sistemi mešanja v vakuumu ter granuliranje in peletiranje v eni sami napravi.</p>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='comp-granulating' alt='procesna oprema za granuliranje' />
              </div>
              <div className='card-content'>
                <h3>Granuliranje in peletiranje</h3>
                <p>
                  <strong>Mešalci, granulatorji in peletizatorji v enem.</strong>
                </p>
                <p>
                  Eirichovi intenzivni mešalci so že v osnovi zasnovani za potrebe granuliranja in peletiranja mešanice. Dolgoletne izkušnje in strokovno znanje zagotavljajo homogeni material s
                  predvidljivimi velikostmi zrn v vsaki seriji mešanja, granuliranja in peletiranja.
                </p>
                <p>Celoten proces je možno izvesti v normalni atmosferi ali v vakuumu.</p>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='comp-grinding' alt='procesna oprema za mletje, mlini' />
              </div>
              <div className='card-content'>
                <h3>Mletje</h3>
                <p>
                  <strong>Prilagojeni in efektivni mlini.</strong>
                </p>
                <p>Eirichovi mlini so primerni za industrijo mineralov, rudnin ter mletje surovin za keramično in steklarsko industrijo ter proizvodnjo gnojil.</p>
                <p>Različni principi mletja pokrivajo potrebe za mletje mehkih in trdih materialov ter omogočajo mokro in suho mletje tudi visoko abrazivnih materialov.</p>
                <p>
                  Pravilna izbira mlina ima velik vpliv na profitabilnost celotnega proizvodnega procesa, kot tudi na kvaliteto izhodnega materiala. Individualni pristop in prilagojeni mlini so zato
                  ključni za dober proizvodni proces.
                </p>
              </div>
            </div>
          </div>

          <div className='third card-container'>
            <div className='card'>
              <div className='card-img'>
                <Picture file='comp-crushing' alt='procesna oprema za drobljenje, industrijski drobilci' />
              </div>
              <div className='card-content'>
                <h3>Drobljenje</h3>
                <p>
                  <strong>Trije principi mletja v eni napravi.</strong>
                </p>
                <p>
                  Vortex drobilci slovijo po svoji učinkovitosti in izjemni fleksibilnosti vhodnega materiala. Vzdrževanje je enostavno, kar je še posebej pomembno pri visoko abrazivnih materialih.
                </p>
                <p>
                  Vortex drobilci imajo večjo propustnost in zagotavljajo večji odstotek ciljne frakcije v izhodnem materialu. S tem dosežete ciljno količino hitreje in s tem potrošite manj resursov.
                </p>
                <p>Vortex drobilci delujejo na kombiniranem principu čeljustnih drobilcev in udarnih drobilcev ter mlinov.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Competencies;
