import React from "react";

import "./Brands.scss";
import "../../logos.scss";
import "../../content.scss";
import Form from "../../containers/Form/Form";

import { ReactComponent as AllgaierLogo } from "../../assets/logos/allgaier.svg";
import { ReactComponent as MozerLogo } from "../../assets/logos/mozer.svg";
import { ReactComponent as MogensenLogo } from "../../assets/logos/mogensen.svg";
import { ReactComponent as GosagLogo } from "../../assets/logos/gosag.svg";
import { ReactComponent as EirichLogo } from "../../assets/logos/eirich.svg";
import { ReactComponent as VortexLogo } from "../../assets/logos/vortex.svg";

const formElements = {
  name: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Vaše ime"
    },
    label: {
      show: false,
      name: "Ime in priimek"
    },
    validation: {
      required: true
    },
    valid: false,
    touched: false,
    value: ""
  },
  company: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Podjetje"
    },
    label: {
      show: false,
      name: "Podjetje"
    },
    validation: {
      required: true
    },
    valid: false,
    touched: false,
    value: ""
  },
  email: {
    elementType: "input",
    elementConfig: {
      type: "email",
      placeholder: "E-naslov"
    },
    label: {
      show: false,
      name: "E-naslov"
    },
    validation: {
      required: true
    },
    valid: false,
    touched: false,
    value: ""
  },
  phone: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Telefonska številka"
    },
    label: {
      show: false,
      name: "Telefon"
    },
    validation: {
      required: true
    },
    valid: false,
    touched: false,
    value: ""
  }
};

const Brands = () => {
  return (
    <div className='container'>
      <div className='content brands base-bg'>
        <div>
          <h2>Procesna tehnologija - proizvajalci</h2>
          <p>
            Zastopamo vrhunske proizvajalce na področju procesne opreme z dolgoletno tradicijo in potrjeno učinkovitostjo. Za vas bomo poiskali najboljšo tehnološko rešitev na različnih industrijskih
            področjih.
          </p>
        </div>
        <div>
          <h2 className='text-center'>Področja</h2>
          <ul className='row flex-wrap'>
            <li className='half'>sejanje</li>
            <li className='half'>sortiranje</li>
            <li className='half'>industrijsko pranje</li>
            <li className='half'>sušenje</li>
            <li className='half'>mešanje</li>
            <li className='half'>drobljenje</li>
            <li className='half'>mletje</li>
            <li className='half'>granuliranje</li>
            <li className='half'>peletiranje</li>
          </ul>
        </div>
        <div className='dark'>
          <h2 className='text-center'>Kontaktirajte nas</h2>
          <Form id='contact-form-brands' elements={formElements} submit='Pošlji' />
        </div>
      </div>
      <div className='content brands'>
        <h2>Proizvajalci</h2>
        <div className='row flex-wrap'>
          <div className='third-2 row flex-wrap'>
            <div className='card-container allgaier'>
              <div className='card'>
                <div className='card-img'>
                  <AllgaierLogo className='logos allgaier' />
                </div>
                <div className='card-content'>
                  <h3>Allgaier skupina</h3>
                  <blockquote>Inovativne rešitve za predelavo sipkih materialov, prilagojene za vas.</blockquote>
                  <p>
                    Allgaier skupina pod seboj združuje blagovne znamke Allgaier, Mogensen, Gosag in Mozer, s katerimi so prisotni v preko 40 državah. Ponujajo tako standardizirane in prilagojene
                    sisteme za:
                  </p>
                  <ul>
                    <li>industrijsko pranje,</li>
                    <li>sušenje,</li>
                    <li>hlajenje,</li>
                    <li>sejanje in</li>
                    <li>sortiranje</li>
                  </ul>
                  <p>vseh vrst sipkih materialov.</p>
                </div>
              </div>
            </div>

            <div className='card-container half'>
              <div className='card'>
                <div className='card-img'>
                  <AllgaierLogo className='logos allgaier' />
                </div>
                <div className='card-content'>
                  <h3>Allgaier</h3>
                  <blockquote>Patentirani presejalni sistemi za zagotavljanje najstrožjih zahtev kakovosti sejanja do zelo fine stopnje.</blockquote>
                  <p>
                    Allgaier izdeluje posamezne stroje in tudi celotne sistemske rešitve na ključ, ki jih popolnoma prilagodijo uporabniku. Njihove strokovne ekipe vsakodnevno skrbijo za zadovoljstvo
                    preko 10.000 strank v več kot 40 državah in jim pomagajo pri oblikovanju novih metod inženirskih procesov ter optimizaciji njihovega trenutnega poslovanja.
                  </p>
                  <p>Allgaier ponuja:</p>
                  <ul>
                    <li>krožna sita,</li>
                    <li>vibracijske presejalne naprave, </li>
                    <li>visoko zmogljive bobnaste sušilnike, </li>
                    <li>sušilce z fluidno posteljo, </li>
                    <li>diskaste sušilnike in </li>
                    <li>kombinirane sisteme za sušenje in</li>
                    <li>hlajenje.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='card-container half'>
              <div className='card'>
                <div className='card-img'>
                  <MogensenLogo className='logos mogensen' />
                </div>
                <div className='card-content'>
                  <h3>Mogensen</h3>
                  <blockquote>Inovativne tehnike sortiranja.</blockquote>
                  <p>
                    Mogensen presejalni stroji zajemajo široko paleto materialov. Učinkovito ločujejo drobne materiale, velike kapacitete materiala srednjih velikosti, pa vse do velikih materialov, ki
                    tehtajo do 3 tone.
                  </p>
                  <p>
                    Sistem Mogensen MSort je edinstven sistem sortiranja, ki razvršča razsute materiale po barvi, prosojnosti, obliki, velikosti in kemični sestavi, vključno z možnostjo vgradnje
                    različnih metod detekcije. Trenutno po svetu uspešno obratuje preko 400 MSort naprav.
                  </p>
                  <p>Mogensen ponuja:</p>
                  <ul>
                    <li>zmogljive presejalne naprave z inovativnim designom,</li>
                    <li>optično sortiranje MSort,</li>
                  </ul>
                  <p>podjetjem iz mineralne, rudarske, krmne, kemične, reciklažne, farmacevtske in prehrambene industrije ter industrije bio goriv.</p>
                </div>
              </div>
            </div>
            <div className='card-container half'>
              <div className='card'>
                <div className='card-img'>
                  <MozerLogo className='logos mozer' />
                </div>
                <div className='card-content'>
                  <h3>Mozer</h3>
                  <blockquote>Bobnasti sušilniki in hladilniki.</blockquote>
                  <p>
                    Mozer je blagovna znamka pod katero Allgaier izdeluje bobnaste sušilnike in hladilnike. Primerni so za sušenje in/ali hlajenje sipkih materialov. Njihova značilnost je visoka
                    kapaciteta.
                  </p>
                  <p>
                    Mozer bobnasti sušilniki so prilagodljivi in lahko sušijo/hladijo različne materiale s kapaciteto od 1 t/h pa tudi preko 350 t/h. Intenziven prenos mase in toplote znotraj bobnov
                    prispeva k zelo učinkovitemu procesu sušenja in hlajenja. Čeprav lahko sušij veliko različnih materialov so najprimernejši za minerale, kemikalije, gnojila in odpadno industrijo.
                  </p>
                </div>
              </div>
            </div>
            <div className='card-container half'>
              <div className='card'>
                <div className='card-img'>
                  <GosagLogo className='logos gosag' />
                </div>
                <div className='card-content'>
                  <h3>Gosag</h3>
                  <blockquote>Sistemi za pranje, sejanje in sortiranje sipkih materialov.</blockquote>
                  <p>Gosag ponuja:</p>
                  <ul>
                    <li>naprave za pranje peska,</li>
                    <li>sita in presejalne naprave,</li>
                    <li>specialne distribucijske podajalnike,</li>
                    <li>denzimetrične mize.</li>
                  </ul>
                  <p>Poleg posameznih strojev ponujajo tudi celovite rešitve na ključ za pranje frac peska vključno s pralnimi enotami, distribucijskimi podajalniki in presejalniki.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='third'>
            <div className='card-container'>
              <div className='card'>
                <div className='card-img'>
                  <EirichLogo className='logos eirich' />
                </div>
                <div className='card-content'>
                  <h3>Eirich</h3>
                  <blockquote>Vrhunski in inovativni mešalci.</blockquote>
                  <p>Eirich izdeluje stroje in celotne sisteme za potrebe:</p>
                  <ul>
                    <li>mešanja,</li>
                    <li>ganuliranja,</li>
                    <li>peletiranja,</li>
                    <li>sušenja in finega mletja.</li>
                  </ul>
                  <p>Njihove glavne kompetence so na področjih priprave sipkih materialov in različnih vrst tekočin in blata.</p>
                  <p>Eirich slovi kot najboljši proizvajalec mešalcev na svetu.</p>
                </div>
              </div>
            </div>
            <div className='card-container'>
              <div className='card'>
                <div className='card-img'>
                  <VortexLogo className='logos vortex' />
                </div>
                <div className='card-content'>
                  <h3>Vortex</h3>
                  <blockquote>Drobilci 3v1</blockquote>
                  <p>
                    Vortex drobilci so izjemno učinkoviti in prilagodljivi na več vrst vstopnih materialov ter različne kvalitete izstopnega materiala. Primerni so za materiale različnih trdot, od
                    mehkih in krhkih materialov do trdih kamnov in ne-železnih reciklažnih materialov.
                  </p>
                  <p>Vortex drobilci imajo visoko kapaciteto in izredno visok odstotek želenega izhodnega materiala.</p>
                  <p>Vsi Vortex drobilci združujejo tri principe drobljenja:</p>
                  <ul>
                    <li>čeljustne drobilce,</li>
                    <li>udarne drobilce in</li>
                    <li>mline.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Brands;
