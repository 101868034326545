import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import "./Menu.scss";
import Logo from "../../containers/Logo/Logo";
import CloseIcon from "../../containers/Icons/CloseIcon";

import * as actions from "../../store/actions/";

const Menu = ({ desktop = false }) => {
  const open = useSelector(state => state.options.menuOpen);
  const mainMenu = useSelector(state => state.menus.main);
  const secondaryMenu = useSelector(state => state.menus.secondary);
  const menuItems = useSelector(state => state.menus.items);
  const dispatch = useDispatch();
  const onMenuClose = useCallback(() => dispatch(actions.setMenuClose()), [dispatch]);

  // console.log(desktop);
  const menuArray = desktop ? [...mainMenu] : [...mainMenu, ...secondaryMenu];
  const menu = menuArray.map(item => (
    <li key={item}>
      <NavLink to={`/${item}/`} activeClassName='active' onClick={onMenuClose}>
        {menuItems[item].name}
      </NavLink>
    </li>
  ));

  return (
    <nav id='menu--main' className={open ? "menu--main-show" : null} aria-label='main-navigation'>
      <div className='menu--header'>
        <div>
          <CloseIcon click={onMenuClose} />
        </div>
        <div className='menu--logo'>
          <Logo />
        </div>
        <div></div>
      </div>
      <ul>{menu}</ul>
    </nav>
  );
};
export default Menu;
