import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import "./Footer.scss";
import Logo from "../../../containers/Logo/Logo";
import ConnectBox from "../../../containers/ConnectBox/ConnectBox";

const Footer = () => {
  const servicesMenu = useSelector(state => state.menus.services);
  const legalMenu = useSelector(state => state.menus.legal);
  const menuItems = useSelector(state => state.menus.items);

  const services = servicesMenu.map(item => (
    <li key={item}>
      <NavLink to={`/${item}/`} activeClassName='active'>
        {menuItems[item].name}
      </NavLink>
    </li>
  ));

  const legal = legalMenu.map(item => (
    <li key={item}>
      <NavLink to={`/${item}/`} activeClassName='active'>
        {menuItems[item].name}
      </NavLink>
    </li>
  ));

  return (
    <div id='footer'>
      <div className='container'>
        <ConnectBox />
        <Logo color='#fff' />
        <div className='footer__text'>Procesna oprema vrhunske kakovosti. Sita, mešalci, sušilci, drobilci proizvajalcev Allgaier, Mogensen, Mozer, Gosag, Eirich in Vortex.</div>
        <div id='footer__menu'>
          <div className='footer__menu'>
            <nav aria-label='services-navigation'>
              <h3 id='services-navigation'>Storitve</h3>
              <ul>{services}</ul>
            </nav>
          </div>
          <div className='footer__menu'>
            <nav aria-label='legal-info-navigation'>
              <h3 id='legal-info-navigation'>Pravne informacije</h3>
              <ul>{legal}</ul>
            </nav>
          </div>
          <div className='footer__menu' aria-label='footer-contact-info'>
            <h3 id='footer-contact-info'>Kontakt</h3>
            <address>
              Procesna oprema Bitenc d.o.o.
              <br />
              Sp. Voličina 26
              <br />
              2232 Voličina
              <br />
              info@po-bitenc.si
              <br />
              +386 41 678 755
            </address>
          </div>
        </div>
        <div id='footer__copyright'>©{new Date().getFullYear()} Procesna Oprema Bitenc d.o.o.</div>
      </div>
    </div>
  );
};
export default Footer;
