import React from "react";
import { useSelector } from "react-redux";

import "./Title.scss";
import Breadcrumbs from "../../../containers/Breadcrumbs/Breadcrumbs";

const Title = ({ image }) => {
  const activeMenu = useSelector(state => state.menus.active);
  const menuItems = useSelector(state => state.menus.items);

  return (
    <div id='title'>
      <div className='container'>
        <div className='title--bg'>{image && <img src={image} alt='Procesna oprema Bitenc' />}</div>
        <div className='title--content'>
          <h1>{menuItems[activeMenu].title}</h1>
          <Breadcrumbs uri={activeMenu} name={menuItems[activeMenu].name} />
        </div>
      </div>
    </div>
  );
};
export default Title;
