import React from "react";
import { useSelector } from "react-redux";

import "./SiteMsg.scss";

const SiteMsg = () => {
  const msg = useSelector(state => state.options.msg.content);
  const type = useSelector(state => state.options.msg.type);
  const open = useSelector(state => state.options.msg.open);
  return (
    <div id='site-msg' className={`${type ? type : ""}${open ? " show" : ""}`}>
      {msg}
    </div>
  );
};
export default SiteMsg;
