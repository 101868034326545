import * as actionTypes from "./actionTypes";

export const setMenuOpen = () => {
  return {
    type: actionTypes.SET_MENU_OPEN
  };
};

export const setMenuClose = () => {
  return {
    type: actionTypes.SET_MENU_CLOSE
  };
};

export const setBackdropClose = () => {
  return {
    type: actionTypes.SET_BACKDROP_CLOSE
  };
};

export const setHeaderExpanded = expanded => {
  return {
    type: actionTypes.SET_HEADER_EXPANDED,
    expanded
  };
};

export const setMsgStart = (msg, msgType) => {
  return {
    type: actionTypes.SET_MSG_START,
    msg,
    msgType
  };
};

export const setMsgEnd = () => {
  return {
    type: actionTypes.SET_MSG_END
  };
};

export const setMsg = (msg, type) => {
  return dispatch => {
    dispatch(setMsgStart(msg, type));
    setTimeout(function() {
      dispatch(setMsgEnd());
    }, 9000);
  };
};
