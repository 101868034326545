import React from "react";

import { ReactComponent as MailIconSvg } from "../../assets/icons/mail.svg";

const MailIcon = ({ color = "#222" }) => {
  return (
    <button className='icon mail'>
      <MailIconSvg fill={color} />
    </button>
  );
};
export default MailIcon;
