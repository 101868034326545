import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import "./SecondaryMenu.scss";

const SecondaryMenu = () => {
  const secondaryMenu = useSelector(state => state.menus.secondary);
  const menuItems = useSelector(state => state.menus.items);

  const menu = secondaryMenu.map(item => (
    <li key={item}>
      <NavLink to={`/${item}/`} activeClassName='active'>
        {menuItems[item].name}
      </NavLink>
    </li>
  ));

  return (
    <nav id='menu--secondary' aria-label='secondary-navigation'>
      <ul>{menu}</ul>
    </nav>
  );
};
export default SecondaryMenu;
