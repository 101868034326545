import firebase from "firebase/app";
import "firebase/firestore";
// import "firebase/auth";
// import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCc0h8_NOCeHBfhSkBdjwSCmIb-wvdcA7o",
  authDomain: "po-bitenc.firebaseapp.com",
  databaseURL: "https://po-bitenc.firebaseio.com",
  projectId: "po-bitenc",
  storageBucket: "po-bitenc.appspot.com",
  messagingSenderId: "581626153377",
  appId: "1:581626153377:web:d9e947ff9870e5e546a3e0",
  measurementId: "G-VMZQPBW5XB"
};

firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export default firebase;
export const db = firebase.firestore();
export const formsRef = db.collection("mail");
// export const storageRef = firebase.storage().ref();
