import React from "react";

import { ReactComponent as PhoneIcon2Svg } from "../../assets/icons/phone2.svg";

const PhoneIcon2 = ({ color = "#222" }) => {
  return (
    <button className='icon phone'>
      <PhoneIcon2Svg fill={color} />
    </button>
  );
};
export default PhoneIcon2;
