import React from "react";

import "./ConnectBox.scss";
import PhoneIcon2 from "../../containers/Icons/PhoneIcon2";
import MailIcon from "../../containers/Icons/MailIcon";

const ConnectBox = () => {
  return (
    <div id='connect_box'>
      <div>
        <strong>Kontaktirajte nas</strong>
      </div>
      <div>
        <MailIcon />
        <a href='mailto:info@po-bitenc.si'>info@po-bitenc.si</a>
      </div>
      <div>
        <PhoneIcon2 />
        +386 41 678 755
      </div>
    </div>
  );
};
export default ConnectBox;
