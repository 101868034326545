import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Backdrop.scss";

import * as actions from "../../../store/actions/";

const Backdrop = () => {
  const open = useSelector(state => state.options.backdrop);
  const dispatch = useDispatch();
  const onBackdropClose = useCallback(() => dispatch(actions.setBackdropClose()), [dispatch]);

  return <div className={`backdrop ${open ? " backdrop-open" : null}`} onClick={() => onBackdropClose()}></div>;
};
export default Backdrop;
