import React from "react";

import { ReactComponent as CloseIconSvg } from "../../assets/icons/close.svg";

const CloseIcon = ({ color = "#fff", click }) => {
  return (
    <button className='icon close' onClick={() => click(false)}>
      <CloseIconSvg fill={color} />
    </button>
  );
};
export default CloseIcon;
