import React from "react";

import "../../content.scss";

const Privacy = () => {
  return (
    <div className='container'>
      <div className='content privacy'>
        <h2>Varovanje osebnih podatkov</h2>

        <h3>Kdo smo</h3>
        <p>
          Naš spletni naslov je: <a href='/'>https://www.po-bitenc.si</a>. Več o nas si lahko preberete na strani <a href='/o-nas-procesna-oprema/'>O nas</a>.
        </p>
        <h3>Katere osebne podatke zbiramo in zakaj jih zbiramo</h3>
        <h4>Registracija</h4>
        <p>
          Pri registraciji zbiramo le podatke, ki jih sami vpišete oziroma jih potrdite pri uporabi avtentitkacije s strani drugih ponudnikov (npr. Google, Facebook). Pri registraciji so posredovani
          podatki natačno določeni in vidni in jih morate eksplicitno potrditi. Drugih podatkov o vas ne zbiramo.
        </p>
        <h4>Komentarji in ocene</h4>
        <p>
          Ko obiskovalci pustijo komentarje na spletnem mestu, zbiramo podatke, ki so prikazani v obrazcu za komentiranje, ter naslov IP-ja obiskovalca in uporabniški niz brskalnika za pomoč pri
          odkrivanju neželenih sporočil (spam). Storitvi Gravatar se lahko zagotovi anonimiziran niz, ustvarjen iz vašega e-poštnega naslova (imenovano tudi hash), da lahko preverimo ali jo
          uporabljate. Politika zasebnosti storitve Gravatar je na voljo tukaj:{" "}
          <a href='https://automattic.com/privacy/' target='_blank' rel='noopener noreferrer'>
            https://automattic.com/privacy/
          </a>
          . Po potrditvi vašega komentarja je vaša slika profila prikazana javnosti v okviru vašega komentarja.
        </p>
        <h4>Mediji</h4>
        <p>
          Če nalagate slike na spletno mesto, se morate izogibati nalaganju slik z vgrajenimi lokacijskimi podatki (EXIF GPS). Obiskovalci spletnega mesta lahko prenesejo in pridobivajo vse podatke o
          lokaciji iz slik na spletni strani.
        </p>
        <h4>Kontaktni obrazci</h4>
        <p>
          V kontaktnih obrazcih zbiramo podatke, ki so potrebni za učinkovito komuniciranje z vami. Zahtevana podatki so jasno označeni z * v vsakem obrazcu. Neobvezne podatke lahko vpišete poljubno
          in služijo le za lažje in učinkovitejše komuniciranje. Te podatke uporabljamo le za kontaktiranje v zvezi s tematiko poslano preko kontaktnega obrazca.
        </p>
        <h4>Piškotki</h4>
        <p>
          Spletna aplikacija uporablja minimalno količino piškotkov. Ob sprejetju obvestila o piškotkih bomo za vas nastavili piškote brez osebnih podatkov, ki bo brskalniku omogočal, da si vašo
          izbiro o sprejetju piškotkov zapomni. Piškotek velja eno leto. Ime piškotka je "CookieConsent". Spletna aplikacija uporablja storitev Google Analytics, ki na vaš računalnik shrani piškotke v
          skladu s splošnimi pogoji Google Analytics. Vsi podatki poslani Google Analytics so anonimizirani. Več o načinu kako Google lahko zbira in uporablja vaše podatke si preberite tukaj:
          <a href='https://policies.google.com/privacy?hl=en' target='_blank' rel='noopener noreferrer'>
            https://policies.google.com/privacy?hl=en
          </a>
          .
        </p>
        <h4>Vdelana vsebina z drugih spletnih mest</h4>
        <p>
          Članki na tej spletni strani lahko vsebujejo vdelane vsebine (npr. videoposnetke, slike, članke itd.). Vdelana vsebina z drugih spletnih mest se obnaša na enak način, kot če bi obiskovalec
          obiskal drugo spletno mesto. Ta spletna mesta lahko zbirajo podatke o vas, uporabljajo piškotke, vdelajo dodatno sledenje tretji osebi in nadzirajo vašo interakcijo s to vdelano vsebino,
          vključno s sledenjem interakcije z vdelano vsebino, če imate račun in ste prijavljeni na to spletno mesto.
        </p>
        <h3>Analitika</h3>
        <h4>Zbiranje in analiza podatkov</h4>
        <p>Podatke o obisku in uporabi naše spletne aplikacije zbiramo s pomočjo Google Analytics.</p>
        <h4>S kom delimo vaše podatke</h4>
        <p>Vaših podatkov ne delimo z nikomer.</p>
        <h3>E-novice</h3>
        <p>
          Če ste se prijavili za naše novice, lahko prejmete e-poštna sporočila od nas. To vključuje, vendar ni omejeno na transakcijska e-poštna sporočila in tržna e-poštna sporočila. Pošiljamo le
          e-poštna sporočila na katera ste se izrecno ali implicitno prijavili (registracija, vpis itd.). Ob prijavi zberemo vaš e-poštni naslov, vaše ime (opcijsko), vaš trenutni IP-naslov in časovni
          žig prijave ter trenutni spletni naslov na katerem ste se prijavili. Naša e-poštna sporočila pošiljamo preko SMTP gostitelja mail.po-bitenc.si Ko prejmete e-pošto od nas, sledimo, če odprete
          e-poštno sporočilo v svojem e-poštnem odjemalcu, če kliknete povezavo v e-poštnem sporočilu in vaš trenutni IP-naslov.
        </p>
        <h3>Kako dolgo bomo zadržali vaše podatke</h3>
        <p>
          Če pustite komentar, se komentar in njegovi metapodatki ohranijo za nedoločen čas. To je zato, da lahko samodejno prepoznamo in odobravamo nadaljnje komentarje, namesto da jih zadržimo v
          čakalni vrsti. Za uporabnike, ki se prijavijo na naši spletni strani, lahko shranjujemo tudi osebne podatke. Če smo vaše osebne podatke shranili jih lahko vidite v svojem uporabniškem
          profilu. Vsi uporabniki si lahko kadar koli ogledujejo, urejajo ali izbrišejo svoje shranjene osebne podatke z izjemo uporabniškega imena. Skrbniki spletnih mest si lahko ogledajo in uredijo
          te podatke.
        </p>
        <h3>Kakšne pravice imate nad vašimi podatki</h3>
        <p>
          Če imate na tej spletni strani račun ali ste pustili komentarje, lahko zahtevate, da prejmete izvoženo datoteko osebnih podatkov, ki jih imamo o vas, vključno s podatki, ki ste nam jih
          posredovali. Prav tako lahko zahtevate, da izbrišemo vse osebne podatke, ki jih imamo o vas. To ne vključuje podatkov, ki jih moramo hraniti v administrativne, pravne ali varnostne namene.
        </p>
        <h3>Kam pošljemo podatke</h3>
        <p>Vaših podatkov ne posredujemo nikomur.</p>
        <h3>Naš kontakt</h3>
        <p>Kontaktirate nas lahko preko podatkov objavljeni na strani Kontakt.</p>
        <h3>Dodatne informacije</h3>
        <h4>Kako zaščitimo vaše podatke</h4>
        <p>Vaši podatki so zbrani na varnem strežniku v kriptirani bazi podatkov. Dostop je zaščiten z varnim ključem ter uporabniškim imenom in geslom.</p>
        <h4>Kakšne postopke uvedemo v primeru kršitev povezanih s zvašimi osebnimi podatki</h4>
        <p>V kolikor ugotovimo karšno koli kršitev varovanja osebnih podatkov smo zavezani v roku treh dni obvestiti pristojne organe.</p>
        <h3>Splošni pogoji poslovanja</h3>
        <p>
          Del tega dokumenta so tudi <a href='/pravne-informacije/'>Splošni pogoji poslovanja</a>. Ob strinjanju s pogoji navedenimi v tem dokumentu (
          <a href='/obvestilo-o-zasebnosti/'>Varovanje osebnih podatkov</a>) se strinjate tudi s <a href='/pravne-informacije/'>Splošnimi pogoji poslovanja</a>.
        </p>
        <p>
          <i>Maribor, 10. februar 2020</i>
        </p>
      </div>
    </div>
  );
};
export default Privacy;
