import React from "react";

import "./TopBar.scss";
import SecondaryMenu from "../../components/SecondaryMenu/SecondaryMenu";
import PhoneIcon2 from "../Icons/PhoneIcon2";
import MailIcon from "../Icons/MailIcon";

const TopBar = () => {
  return (
    <div id='topbar'>
      <div className='container'>
        <div>
          <span>
            <PhoneIcon2 />
            +386 41 678 755
          </span>
          <span>
            <MailIcon />
            <a href='mailto:info@po-bitenc.si'>info@po-bitenc.si</a>
          </span>
        </div>
        <SecondaryMenu />
      </div>
    </div>
  );
};
export default TopBar;
